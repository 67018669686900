.importPopUp {
  position: absolute;
  right: 24px;
  top: 42px;
  min-width: 250px;
  z-index: 15;

  list-style: none;
  padding:  16px 0;

  background-color: white;
  border-radius: 5px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

  display: flex;
  flex-direction: column;
}
