.home {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  background-color: #F5F5FA;
  margin-left: min(20%, 300px);
  padding: 16px;
  box-sizing: border-box;
}

.project {
  background-color: rgba(79, 103, 243, 0.55);;
  border-radius: 50px;

  height: 32px;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  color: white;
  font-weight: 500;
  font-size: 18px;
}

.project p {
  margin: 0;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .project {
    width: 90%;
    height: unset;
    padding: 16px;
  }

  .home {
    margin-left: 0;
  }
}
