@value colors: "../../components/library/style/colors.css";
@value color-purple, color-purple-dark, color-purple-gray, color-gray-light, color-text-subtle, color-error-light, color-error-dark from colors;


.type {
  width: 30%;
  background-color: white;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.10);
  border-radius: 5px;
  padding: 16px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.type h2 {
  font-size: 14px;
  margin: 0;
  color: color-text-subtle;
}

.container {
  min-height: 150px;
}

.emptyWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.empty {
  font-size: 24px;
  font-weight: 500;
  color: color-text-subtle;
}

.list {
  padding-left: 0;
  list-style: none;
  max-height: 20vh;
  overflow-y: scroll;
  scrollbar-color: rgba(0, 0, 0, 0.1) transparent; /* thumb and track color */
  scrollbar-width: thin;
}

.list::-webkit-scrollbar {
  width: 5px;  /* for vertical scrollbars */
}

.list::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}


.list li {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  padding: 8px;
  transition: .3s;
}

.list li p {
  margin: 0;
}

.list li button {
  opacity: 0;
  transition: .2s;
}

.list li:hover {
  background-color: color-purple-gray;
}

.list li:hover button {
  opacity: 1;
}

.inputWrapper {
  display: flex;
  flex-direction: column;
}

.inputWrapper textarea {
  width: 100%;
  height: 82px;
  padding-left: 8px;
  box-sizing: border-box;
  border-radius: 5px;

  background-color: color-purple-gray;
  border: none;
  outline: none;
}

.inputWrapper button {
  width: 20%;
  background-color: color-purple;
  color: white;
  height: 42px;
  border-radius: 5px;
  transition: .3s;
  margin-top: 8px;
}

.inputWrapper button:hover {
  background-color: color-purple-dark;
}

.error {
  margin: 16px 0 0 0;
  padding: 8px;
  border-radius: 5px;
  background-color: color-error-light;
  color: color-error-dark;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 24px;
  width: 100%;
  padding: 0;
}

.header h3 {
  margin: 0;
  font-size: 16px;
}

.header:hover {
  color: color-purple;
  cursor: pointer;
}

.toolbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.searchbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 32px;
  border-radius: 25px;
  transition: .3s;
}

.searchbar.show {
  background-color: color-purple-gray;
  border: 1px solid color-purple-gray;
}

.searchbar.hide:hover {
  color: color-purple;
}

.searchbar.show:focus-within {
  border: 1px solid color-purple;
  color: color-purple;
}

.searchbar input {
  border: none;
  background: none;
  outline: none;
  padding-left: 8px;
}

.searchbar button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon {
  font-size: 18px;
}

.submit {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.hint {
  opacity: 0;
  width: 60%;
  font-size: 14px;
  transition: .3s;
  margin-bottom: 0;
}

.inputWrapper:focus-within .hint {
  opacity: 1;
}
