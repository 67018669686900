@value colors: "../../../../components/library/style/colors.css";
@value color-text-subtle, color-purple, color-gray-light, color-error-light, color-error-dark from colors;

.background {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;

    width: 100%;
    height: 100vh;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: rgba(255, 255, 255, 0.5);
}

.modal {
    background-color: white;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);

    width: 90%;
    max-width: 320px;
    border-radius: 5px;
    padding: 16px;
    box-sizing: border-box;
}

.modal > div {
    max-height: 200px;
}

.title {
    text-align: center;
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 0;
}

.button {
    width: 100%;
}

.description {
    color: color-text-subtle;
    text-align: center;
}

.custom {
    color: color-text-subtle;
    width: unset;
    height: unset;
    min-width: unset;
    padding: 0;
    transition: .3s;
    font-size: 14px;
    margin: 24px 0 8px auto;
}

.icon {
    font-size: 14px;
}

.custom:hover {
    color: color-purple;
}

.token {
    background-color: color-gray-light;
    border-radius: 5px;
    padding: 8px;
    white-space: nowrap;
    overflow-x: scroll;
    scrollbar-color: rgba(0, 0, 0, 0.1) transparent; /* thumb and track color */
    scrollbar-width: thin;
    margin-top: 0;
    margin-bottom: 24px;
}

.token::-webkit-scrollbar {
    height: 4px;
}

.token::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.1);
    border-radius: 4px;
}

.error {
    background-color: color-error-light;
    color: color-error-dark;
    padding: 8px;
    border-radius: 5px;
    font-size: 14px;

    display: flex;
}

.error .icon {
    margin-right: 8px;
    font-size: 18px;
}

.lineHeight {
    line-height: 200%;
}
