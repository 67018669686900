@value colors: "../library/style/colors.css";
@value color-purple-gray, color-text-subtle from colors;

.searchbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  background-color: color-purple-gray;
  color: color-text-subtle;
  border-radius: 5px;
  padding: 8px;
  height: 48px;
  box-sizing: border-box;
  min-width: 320px;
}

.input {
  border: none;
  background: none;
  outline: none;

  font-family: Roboto;
}

.button .icon {
  color: color-text-subtle;
}
