@value colors: "../../../../components/library/style/colors.css";
@value color-purple, color-purple-dark, color-purple-gray from colors;

.toolbarContainer {
  margin-bottom: 16px;
  height: auto;
}

.toolbar {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-wrap: wrap;
}

.searchbar {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.advanced {
  color: color-purple;
  display: flex;
  align-items: center;
  margin-left: 16px;
}

.advanced:hover {
  color: color-purple-dark;
}

.button {
  background-color: color-purple;
  color: white;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  height: 100%;
  transition: .3s;
}

.button:hover {
  background-color: color-purple-dark;
}

.button .icon {
  margin-left: 8px;
}

.dropdown {
  position: relative;
}

.download {
  text-align: left;
  font-weight: 400;
  font-size: 16px;
  height: 40px;
  transition: .3s;
}

.download:hover {
  background-color: color-purple-gray;
}

.dropdown > div {
  width: 300px;
}

.rowButton {
  display: flex;
  flex-direction: row;
}

.rowButton > button:first-child {
  margin-right: 16px;
}

.overlay {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 100;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-items: center;

  background-color: rgba(0, 0, 0, 0.5);;
}

.modal {
  background-color: white;
  padding: 16px;
  border-radius: 5px;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal h2 {
  font-size: 18px;
  margin-bottom: 0;
}

.row {
  display: flex;
  flex-direction: row;
}
@media screen and (max-width: 540px) {
  .toolbar {
    justify-content: flex-end;
    flex-direction: column-reverse;
  }

  .button {
    height: 48px;
    margin-bottom: 16px;
  }

  .searchbar {
    flex-direction: column;
  }

  .advanced {
    margin-left: 0;
    margin-top: 16px;
    text-align: right;
  }
}
