@value colors: '../../../../../../components/library/style/colors.css';
@value color-purple, color-purple-gray, color-gray-light, color-success, color-error, color-warning, color-text-subtle, color-gray from colors;


.accountRow {
  border-bottom: 1px solid color-purple-gray;
}

.grid {
  display: grid;
  grid-template-columns: 1fr 24fr;
  padding: 8px;
  border-bottom: 1px solid color-purple-gray;
}

.grid p {
  margin: 0;
}

.email {
  font-weight:  500;
}

.description {
  color: color-text-subtle;
  font-size: 16px;
}

.potential .row {
  display: grid;
  grid-template-columns: 1fr 24fr;
  padding: 8px;
  background-color: color-purple-gray;
}

.potential .row + .row {
  border-top: 1px solid white;
}

.potential .row p {
  margin: 8px 0;
}

.reason {
  margin-left: 4px;
  color: color-text-subtle;
}
