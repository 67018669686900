@value colors: "../../../../../../components/library/style/colors.css";
@value color-success, color-error from colors;

.details {
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
}

.title {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  margin: 0;
  margin-bottom: 16px;
}

.label {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #838383;
  text-transform: uppercase;

  margin-bottom: 4px;
  margin-top: 0;
}

.row {
  display: flex;
  flex-direction: row;
}

.row .detail + .detail {
  margin-left: 24px;
}

.value {
  margin: 0;
}

.subscribe {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: color-success;
}

.unsubscribe {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: color-error;
}

.subscribe .icon, .unsubscribe .icon {
  margin-right: 4px;
}

.subscribe p, .unsubscribe p {
  margin: 0;
  font-weight: 500;
}

.divider {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 16px;
}

.source {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 16px;
}

.source p {
  margin: 0;
}
