@value colors: '../../../../components/library/style/colors.css';
@value color-purple, color-purple-bg, color-gray, color-gray-light, color-text-subtle from colors;

.finder {
  width: 30%;
  box-sizing: border-box;
}

.row {
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
}

.row button {
  height: 42px;
  padding: 0 16px;

  display: flex;
  align-items: center;
  justify-content: center;

  transition: .3s;
}

.button {
    color: color-text-subtle;
}

.selected {
  background-color: color-purple-bg;
  color: color-purple;
  border-radius: 25px;
}

.searchbar {
  width: 100%;
  height: 42px;
  border-radius: 5px;
  border: none;
  padding-left: 8px;
  box-sizing: border-box;

  background-color: #F5F5FA;
  color: color-purple;
  margin-bottom: 16px;
}

.container {
  background-color: #F5F5FA;
  padding: 16px;
  border-radius: 5px 5px 0 0;
  box-sizing: border-box;
}

.searchbar:focus {
  outline: none;
  border: 1px solid color-purple;
  color: color-purple
}

.list {
  list-style: none;
  padding-left: 0;
  margin-top: 0;

  height: 60vh;
  overflow: hidden;
  overflow-y: auto;

  scrollbar-color: rgba(0, 0, 0, 0.1) transparent; /* thumb and track color */
  scrollbar-width: thin;
}

.list::-webkit-scrollbar
{
width: 5px;  /* for vertical scrollbars */
height: 12px; /* for horizontal scrollbars */
}

.list::-webkit-scrollbar-thumb
{
background: rgba(0, 0, 0, 0.1);
border-radius: 5px;
}


.list li {
  min-height: 48px;
  padding: 8px;
  margin-bottom: 8px;

  display: flex;
  align-items: center;

  transition: .3s;
  cursor: pointer;
  color: color-text-subtle;
}

.list li button {
  min-height: 48px;
}

.list li:hover {
  background-color: white;
  border-radius: 5px;
  border: none;
  color: #000;
}

.listButton p {
  margin: 0;
  text-align: left;
}

.listButton .date {
  font-size: 14px;
  margin-top: 4px;
}

.listButton .id {
  font-size: 16px;
  font-weight: 400;
}
