@value colors: "../../../components/library/style/colors.css";
@value color-gray, color-purple from colors;


.details {
  padding: 24px;
}

.toolbar {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 24px;
}

.toolbar input {
  height: 42px;
  border: none;
  border-radius: 5px;
  border: 1px solid color-gray;
  width: 25%;
  margin-right: 16px;;
  outline: none;
  padding-left: 8px;
}
