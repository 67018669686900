@value colors: "../../../../../../../components/library/style/colors.css";
@value color-purple-gray, color-purple, color-gray, color-error-light, color-error-dark, color-success-light, color-success-dark from colors;

.background {
  background: rgba(245, 245, 250, 0.69);

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 100;

  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  background-color: white;
  box-shadow: 0 4px 25px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  overflow: hidden;

  width: 40%;
  max-width: 450px;
}


.header {
  background-color: color-purple-gray;
  padding: 16px;
  margin-bottom: 16px;
}

.header h1 {
  margin: 0;

  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
}

.issuer {
  margin: 0;
  font-weight: 500;
  color: color-purple;
}

.listContainer {
  max-height: 65vh;
  overflow-y: scroll;
  padding: 0 16px;
}

.listContainer::-webkit-scrollbar {
  width: 12px;               /* width of the entire scrollbar */
}

.listContainer::-webkit-scrollbar-track {
  background: white;        /* color of the tracking area */
}

.listContainer::-webkit-scrollbar-thumb {
  background-color: color-gray;    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
  border: 3px solid white;  /* creates padding around scroll thumb */
}

.listContainer {
  scrollbar-width: thin;
  scrollbar-color: color-gray white;
}

.list {
  padding-left: 0;
  list-style: none;
  margin: 0;
}

.item {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  min-height: 40px;
}

.item span {
  font-size: 16px;
}

.item .bold {
  font-weight: 500;
}

.footer {
  display: flex;
  justify-content: flex-end;
  padding: 16px;
  margin-top: 16px;

  border-top: 1px solid color-gray;
}

.relaunch {
  margin-right: 8px;
}

.error {
  display: flex;
  flex-direction: row;
  align-items: center;

  margin: 0;
  padding: 4px;
  border-radius: 5px;

  background-color: color-error-light;
  color: color-error-dark
}

.icon {
  margin-right: 4px;
}

.status {
  padding: 16px 16px 0;
}

.success {
  display: flex;
  flex-direction: row;
  align-items: center;

  margin: 0;
  padding: 4px;
  border-radius: 5px;

  background-color: color-success-light;
  color: color-success-dark
}

.password {
  display: flex;
  align-items: center;
}

.button {
  padding: 4px 8px;
  margin-left: 4px;
  min-height: unset;
  height: unset;
  min-width: unset;
  font-size: 12px;
}
