@value colors: '../../../../components/library/style/colors.css';
@value color-purple, color-purple-gray, color-gray-light, color-success, color-error, color-warning, color-text-subtle, color-gray from colors;

.table {
  width: 100%;
  overflow-x: auto;

  scrollbar-color: rgba(0, 0, 0, 0.1) transparent; /* thumb and track color */
  scrollbar-width: thin;
}

.table::-webkit-scrollbar
{
  width: 5px;  /* for vertical scrollbars */
  height: 12px; /* for horizontal scrollbars */
}

.table::-webkit-scrollbar-thumb
{
  background: rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.tableOverflow {
  min-width: 1024px;
}


.gridTitle {
  display: grid;
  grid-template-columns: 1fr 24fr;
  padding: 8px;
  background-color: color-purple-gray;
  border-radius: 5px 0 5px 0;
}

.gridTitle p {
  font-weight: 500;
  margin: 0;
}
