@value colors: "../../components/library/style/colors.css";
@value color-purple, color-purple-dark, color-purple-gray, color-gray-light, color-text-subtle, color-error-light, color-error-dark from colors;

.main {
  padding: 24px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
