@value colors: "../../../../components/library/style/colors.css";
@value color-text-subtle from colors;

.title {
    font-weight: 500;
    margin-bottom: 8px;
}

.statList {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    row-gap: 8px;
    column-gap: 8px;

    list-style: unset;
    margin: 0;

    background-color: #F5F5FA;
    border-radius: 5px;
    padding: 8px;
}

.statList li {
    list-style: none;
}

.stat {
    background-color: white;
    border-radius: 5px;
    min-width: 125px;
    padding: 8px;
}

.label {
    margin: 0;
    color: color-text-subtle;
    font-size: 14px;
}

.value {
    margin: 0;
    font-size: 24px;
    font-weight: 500;

}

.hint {
    color: color-text-subtle;
    font-size: 14px;
    font-weight: 400;
}
