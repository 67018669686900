.row {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding: 4px 16px;
    border-bottom: 1px solid #F5F5FA;
}

.details {
    text-align: end;
}

.rowDetails {
    display: grid;
    padding: 4px 16px;
    grid-template-columns: repeat(7, 1fr);
}

.hide {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.table {
    background-color: #F5F5FA;
}