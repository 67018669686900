.searchDate {
    width: 100%;
    max-width: 320px;

    position: relative;
}

.icon {
    margin-right: 8px;
}

.button {
    width: 100%;
    padding: 0 16px;
    margin-left: 8px;
}

.dropdown {
    position: absolute;
    top: 52px;
    right: -8px;

    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    border-radius: 5px;

    background-color: white;
    box-shadow: -2px 0 14px rgb(0 0 0 / 18%);

    display: flex;
    flex-direction: column;
}

.dropdown input[type="radio"] {
    margin-right: 8px;
}

.apply {
    margin-top: 8px;
}

.customDate p {
    margin-top: 8px;
}
