@value colors: "../style/colors.css";
@value color-red-bg, color-purple-bg, color-button-primary, color-button-primary-hover, color-button-primary-focus, color-button-secondary, color-button-secondary-hover, color-button-secondary-focus, color-purple-bg, color-success from colors;

.button {
  border-radius: 5px;
  padding: 8px 36px;
  cursor: pointer;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: .3s;
  font-weight: medium;
}

.fill {
  border: none;
}

.primary.fill {
  background-color: color-button-primary;
  color: white;
}

.primary.fill:hover {
  background-color: color-button-primary-hover;
}

.primary.fill:focus {
  background-color: color-button-primary-focus;
}

.secondary.fill {
  background-color: color-button-secondary;
  color: white;
}

.secondary.fill:hover {
  background-color: color-button-secondary-hover;
  color: white;
}

.secondary.fill:focus {
  background-color: color-button-secondary-focus;
  color: white;
}

.disabled.fill {
  background-color: #C0BFCF;
  color: white;
  cursor: not-allowed;
  box-shadow: none;
}

.primary.outline {
  border: 2px solid color-button-primary;
  color: color-button-primary;
  background: none;
}

.primary.outline:hover {
  border: 2px solid color-button-primary-hover;
  color: color-button-primary-hover;
  background-color: color-red-bg;
}

.primary.outline:focus {
  border: 2px solid color-button-primary-focus;
  color: color-button-primary;
  background-color: color-red-bg;
}

.secondary.outline {
  border: 2px solid color-button-secondary;
  color: color-button-secondary;
  background: none;
}

.secondary.outline:hover {
  border: 2px solid color-button-secondary-hover;
  color: color-button-secondary-hover;
  background-color: color-purple-bg;
}

.secondary.outline:focus {
  border: 2px solid color-button-secondary-focus;
  color: color-button-secondary;
  background-color: color-purple-bg;
}

.ghost {
  border: none;
  background: none;
}

.primary.ghost {
  color: color-button-primary;
  padding: 0;
}

.primary.ghost:hover {
  color: color-button-primary-hover;
}

.primary.ghost:focus {
  color: color-button-primary-focus;
}

.secondary.ghost {
  color: color-button-secondary;
  padding: 0;
}

.secondary.ghost:hover {
  color: color-button-secondary-hover;
}

.secondary.ghost:focus {
  color: color-button-secondary-focus;
}

.disabled.ghost {
  color: #C0BFCF;
}

.icon {
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.small {
  height: 38px;
  min-width: 88px;
  font-size: 16px;
}

.medium {
  height: 48px;
  min-width: 112px;
  font-size: 18px;
}

.large {
  height: 72px;
  min-width: 150px;
  font-size: 18px;
}
