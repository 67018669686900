@value colors: "../../../../../../../components/library/style/colors.css";
@value color-purple-gray, color-purple, color-gray, color-error-dark, color-error-light, color-success-dark, color-success-light from colors;

.background {
    background: rgba(245, 245, 250, 0.69);

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 100;

    display: flex;
    justify-content: center;
    align-items: center;
}

.content {
    background-color: white;
    box-shadow: 0 4px 25px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    overflow: hidden;

    width: 40%;
    max-width: 450px;
}

.header {
    background-color: color-purple-gray;
    padding: 16px;
}

.header h1 {
    margin: 0;

    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
}

.main {
    padding: 16px;
}

.main p {
    margin-top: 0;
}

.buttonGroup {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 16px;
    margin-left: auto;
}

.buttonGroup button + button {
    margin-left: 8px;
}

.error {
    padding: 8px;
    color: color-error-dark;
    background-color: color-error-light;
    border-radius: 5px;

    display: flex;
    align-items: center;
}

.success {
    padding: 8px;
    color: color-success-dark;
    background-color: color-success-light;
    border-radius: 5px;

    display: flex;
    align-items: center;
}

.icon {
    margin-right: 4px;
}

.restart input {
    margin-top: 16px;
}

.checkbox {
    margin-right: 8px;
}
