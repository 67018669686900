@value colors: "../../../../components/library/style/colors.css";
@value color-purple-gray, color-purple from colors;

.grid {
  display: grid;
  grid-template-columns: 0.5fr 4fr 2fr 2fr 4fr 2fr 2fr 0.5fr;
  grid-gap: 8px;
  padding: 8px 16px;
  background-color: color-purple-gray;
  border-radius: 5px 5px 0 0;
  align-items: center;
}

.grid p {
  margin: 0;
}

.title p {
  font-weight: 500;
}

.table .tableRow:nth-child(2n + 1) {
  background-color: color-purple-gray;
}

.fixed {
  height: 65vh;
  overflow: auto;
  scrollbar-color: rgba(0, 0, 0, 0.1) transparent; /* thumb and track color */
  scrollbar-width: thin;
}

.fixed::-webkit-scrollbar
{
width: 5px;  /* for vertical scrollbars */
height: 12px; /* for horizontal scrollbars */
}

.fixed::-webkit-scrollbar-thumb
{
background: rgba(0, 0, 0, 0.1);
border-radius: 5px;
}

.result {
  height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.result p {
  font-size: 24px;
}

.widthScroll {
  min-width: 600px;
}

.table {
  width: 100%;
}

.sortable {
  cursor: pointer;
  transition: .3s;
  text-align: left;
  padding: 0;
}

.sortable:hover {
  color: color-purple;
}

@media screen and (max-width: 768px) {
  .table {
    overflow-x: scroll;
  }
}
