@value colors: "../../../../../components/library/style/colors.css";
@value color-gray, color-purple, color-purple-gray, color-text-subtle from colors;

.grid {
  display: grid;
  grid-template-columns: 0.5fr 4fr 2fr 2fr 4fr 2fr 2fr 0.5fr;
  align-items: center;
  padding: 0 16px;
}

.mail {
  max-width: max(200px, 1fr);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 500;
}

.checkbox {
  cursor: pointer;
}

button.mail {
  font-weight: 500;
  transition: .3s;
}

button.mail:hover {
  color: color-purple;
}

.grid button {
  text-align: left;
  width: auto;
}

.editable {
  font-weight: 400;
}

.grid select {
  width: 90%;
  height: 48px;
  box-sizing: border-box;

  border-radius: 5px;
  border-color: color-gray;
  outline: none;

  cursor: pointer;
  transition: .3s;
}

.grid select:hover, .grid select:focus {
  border-color: color-purple;
}

.grid select:focus {
  box-shadow: 0px 0px 6px #b1bdff;
}

.buttonIcon {
  color: color-text-subtle;
}

.importButton:hover, .optionButton:hover {
  background-color: color-purple-gray;
}

.icon {
  font-size: 18px;
  margin-right: 16px;
}

.disabled {
  background-color: color-gray-light;
  color: color-text-subtle;
  pointer-events: none;
}
.optionButton, .disabled {
  font-weight: 400;
  text-align: left;
  display: flex;
  align-items: center;
  height: 42px;
}

.optionButton .icon {
  color: color-purple;
}

.relative {
  position: relative;
}
