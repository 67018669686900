@value colors: "../../../../components/library/style/colors.css";
@value color-text-subtle from colors;

.background {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 100;

    width: 100%;
    height: 100vh;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: rgba(255, 255, 255, 0.85);
}

.modal {
    background-color: white;
    border-radius: 5px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.10);
    padding: 16px;
}

.buttonGroup {
    display: flex;
    justify-content: space-between;
}

.buttonGroup button {
    width: 48%;
}

.title {
    font-weight: 500;
    font-size: 20px;
    text-align: center;
    margin: 0;
}

.description {
    margin-top: 8px;
    color: color-text-subtle;
    text-align: center;
}
