@value colors: "../components/library/style/colors.css";
@value color-purple-gray, color-text-subtle from colors;

.section {
  margin-left: 320px;
  width: 100%;
}


.header {
  width: 100%;
  min-height: 125px;
  padding: 32px 24px 0 24px;;
  box-sizing: border-box;
  background-color: color-purple-gray;
}

.title {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
}

.grid {
  display: grid;
  grid-gap: 8px;
  padding: 8px 16px;
  background-color: color-purple-gray;
  border-radius: 5px 5px 0 0;
}

.grid p {
  margin: 0;
}

.title p {
  font-weight: 500;
}

.table .tableRow:nth-child(2n + 1) {
  background-color: color-purple-gray;
}

.fixed {
  height: 65vh;
  overflow: auto;
  scrollbar-color: rgba(0, 0, 0, 0.1) transparent; /* thumb and track color */
  scrollbar-width: thin;
}

.fixed::-webkit-scrollbar
{
width: 5px;  /* for vertical scrollbars */
height: 12px; /* for horizontal scrollbars */
}

.fixed::-webkit-scrollbar-thumb
{
background: rgba(0, 0, 0, 0.1);
border-radius: 5px;
}

.result {
  height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.result p {
  font-size: 24px;
}

.widthScroll {
  min-width: 600px;
}

.table {
  width: 100%;
}

@media screen and (max-width: 768px) {
  .table {
    overflow-x: scroll;
  }
}
