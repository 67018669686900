@value colors: "../library/style/colors.css";
@value color-purple, color-purple-gray, color-gray-light from colors;

.navigation {
  position: fixed;
  width: 320px;
  height: 100vh;
  background-color: white;
  box-shadow: -2px 0 14px rgba(0, 0, 0, 0.18);
  transition: .3s;
  z-index: 100;
}

.empty {
  padding: 0 24px;
}

.logo {
  font-family: Fjalla One;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  text-transform: uppercase;

  margin: 0;
}

.dot {
  font-family: Fjalla One;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  color: color-purple;
}

.logoDescription {
  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;

  margin: 0;
}

.logoContainer {
  padding: 24px;
  border-bottom: 1px solid color-gray-light;
}

@media (max-height: 650px) {
  .logoContainer {
    display: none;
  }
}

.title {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  text-transform: uppercase;
  color: color-purple;
  margin-top: 0;
}

.searchbar, .general {
  padding: 16px 24px;
}

.general {
  padding-bottom:  12px;
}

.general .title {
  margin-bottom: 0;
}

.list {
  display: flex;
  flex-direction: column;
  list-style: none;
  padding-left: 0;
  margin: 0;
  max-height: 20vh;
  overflow: scroll;
  scrollbar-color: rgba(0, 0, 0, 0.1) transparent; /* thumb and track color */
  scrollbar-width: thin;
}

.projects {
  overflow-y: scroll;
  height: 50%;
  scrollbar-color: rgba(0, 0, 0, 0.1) transparent; /* thumb and track color */
  scrollbar-width: thin;
}

.projects::-webkit-scrollbar, .list::-webkit-scrollbar
{
  width: 5px;  /* for vertical scrollbars */
  height: 12px; /* for horizontal scrollbars */
}

.projects::-webkit-scrollbar-thumb, .list::-webkit-scrollbar-thumb
{
  background: rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.link {
  padding: 8px 24px;
  height: 42px;
  box-sizing: border-box;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-decoration: none;
  color: black;

  transition: .3s;

  display: flex;
  align-items: center;
}

.link:hover, .link:focus, .selected {
  background-color: color-purple-gray;
}

.link span {
  font-size: 16px;
}

.absolute {
  display: none;
}

.menuButton {
  width: 48px;
  height: 48px;
  background-color: color-purple;
  border-radius: 5px;
  color: white;

  bottom: 16px;
  left: 16px;
}

.tabTitle {
  padding: 16px 24px;
}

.tabTitle p {
  margin: 0;
}

.tab {
  padding-left: 0;
  margin: 0;
  max-height: 15vh;
  overflow-y: scroll;
  scrollbar-color: rgba(0, 0, 0, 0.1) transparent; /* thumb and track color */
  scrollbar-width: thin;
}

.tab::-webkit-scrollbar {
  width: 5px;  /* for vertical scrollbars */
}

.tab::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.pin {
  display: flex;
  justify-content: space-between;
}

.pinned {
  color: color-purple;
}

@media screen and (max-width: 1024px) {
  .hide {
    transform: translateX(-100%);
    animation: slide-out 0.5s forwards;
  }

  .show {
    transform: translateX(0);
    animation: slide-in 0.5s forwards;
  }

  .absolute {
    display: block;
    position: fixed;
  }

  .navigation {
    min-width: 320px;
  }
}

@keyframes slide-out {
  0% { transform: translateX(0%); }
  100% { transform: translateX(-100%); }
}

@keyframes slide-in {
  0% { transform: translateX(-100%); }
  100% { transform: translateX(0%); }
}
