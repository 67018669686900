@value colors: "../../library/style/colors.css";
@value color-purple, color-purple-gray, color-gray-light, color-text-subtle from colors;

.container {
  background-color: white;
  border-radius: 5px;
  padding: 8px;
  transition: .3s;
}

.action {
  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 0;
  width: 100%;
}

.action p {
  margin: 0;
}

.action p + p {
  margin-left: 8px;
}

.type {
  font-weight: 400;
}

.details {
  margin-top: 16px;
}

.action:hover {
  color: color-purple;
}
