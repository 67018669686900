@value colors: "../../../components/library/style/colors.css";
@value color-error-light, color-error-dark from colors;

.error {
    background-color: color-error-light;
    color: color-error-dark;
    border-radius: 4px;
    padding: 8px;
}

.bold {
    font-weight: 500;
    margin: 0;
}

.description {
    margin-top: 4px;
    margin-bottom: 4px;
}
