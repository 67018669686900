@value colors: '../../../../components/library/style/colors.css';
@value color-purple, color-purple-gray, color-gray-light, color-success, color-error, color-warning, color-text-subtle, color-gray from colors;

.relative {
  position: relative;
}

.table {
  margin-top: 40px;
  width: 100%;
}

.tableOverflow {
  min-width: 1024px;
}

.tableBody {
  min-height: 50vh;
}

.grid {
  display: grid;
  grid-template-columns: 1fr 6fr 3fr 3fr 3fr 3fr 6fr 4fr 1fr;
  align-items: center;
  padding: 8px;
}

.gridTitle {
  display: grid;
  grid-template-columns: 1fr 6fr 3fr 3fr 3fr 3fr 6fr 4fr 1fr;
  padding: 8px;
  background-color: color-purple-gray;
  border-radius: 5px 0 5px 0;
}

.cellTitle p {
  margin: 0;
}

.title {
  font-size: 16px;
}

.value {
  font-weight: 500;
  font-size: 18px;
}

.source {
  border-bottom: 1px solid color-purple-gray;
}

.list {
  list-style: none;
  padding-left: 0;
  margin: 0;
}

.row {
  background-color: color-purple-gray;
  border-bottom: 1px solid white;
}

.waiting, .done, .loading, .error {
  width: 8px;
  height: 8px;
  border-radius: 8px;
  margin-right: 8px;
}

.done {
  background-color: color-success;
}

.waiting, .error {
  background-color: color-error;
}

.loading {
  background-color: color-warning;
}

.status {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: normal;
}

.button {
  color: color-text-subtle;
}

.reset {
  all: unset;
}

.pointable {
  cursor: pointer;
  font-family: Roboto, sans-serif;
  font-size: 18px;
}

.blacklistInput {
  height: 42px;
  border-radius: 5px;
  border: 1px solid color-gray;
  outline: none;
  width: 80%;
  font-family: Roboto, sans-serif;
  font-size: 18px;
  transition: .3s;
}

.blacklistInput:hover {
  border-color: color-purple;
}

.blacklistInput:focus {
  box-shadow: 0 0 6px #b1bdff;
  border-color: color-purple;
}

.importButton {
  height: 42px;
  font-weight: 400;

  display: flex;
  flex-direction: row;
  align-items: center;

  color: color-error;
}

.importButton:hover, .optionButton:hover {
  background-color: color-purple-gray;
}

.icon {
  font-size: 18px;
  margin-right: 16px;
}

.disabled {
  background-color: color-gray-light;
  color: color-text-subtle;
  pointer-events: none;
}
.row select {
  height: 48px;
  border-radius: 5px;
  width: 90%;
  border: 1px solid color-gray;
}

.row select:hover, .row select:focus {
  border-color: color-purple;
}

.row select:focus {
  box-shadow: 0 0 6px color-purple;
  outline: none;
}

.owner {
  font-weight: 400;
  text-align: left;
  padding: 0;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.optionButton, .disabled {
  font-weight: 400;
  text-align: left;
  display: flex;
  align-items: center;
  height: 42px;
}

.optionButton .icon {
  color: color-purple;
}

.mail {
  text-align: left;
  max-width: max(250px, 1fr);
  overflow: hidden;
  text-overflow: ellipsis;
  transition: .3s;
}

.mail:hover {
  color: color-purple;
}

.blacklistWarn {
  color: color-error;
  font-weight: 500;
}

.statusDropdown {
  position: absolute;
  z-index: 10;
  right: 0;
  width: 100%;
  min-width: 215px;
  border-radius: 5px;
  background-color: white;
  box-shadow: 0 4px 15px rgb(59 77 181 / 16%);
}

.select {
  background-color: white;
  max-width: 100%;
  height: 48px;
  border-radius: 5px;
  border: 1px solid color-gray;
  color: black;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  font-weight: normal;

  overflow: hidden;
  text-overflow: ellipsis;
}

.select .icon {
  margin-right: 0;
}

.select span {
  max-width: 75%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.loadingContainer {
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 0.5rem;
}

.loadingText {
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: 500;
  color: color-text-subtle;
}

.loader {
  border: 4px solid color-gray-light;
  border-radius: 50%;
  border-top: 4px solid color-purple;
  width: 16px;
  height: 16px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
  }
  100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
  }
}

