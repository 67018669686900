@value colors: "../../../components/library/style/colors.css";
@value color-purple from colors;

.list {
    padding: 0 16px;
}

.date {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 16px;

    background-color: #F5F5FA;
    border-radius: 5px;

    padding: 0 16px;
    margin-bottom: 16px;
}

.method {
    font-weight: 500;
    background-color: color-purple;
    color: white;
    border-radius: 4px;
    padding: 4px 8px;
}

.authId {
    margin-right: 4px;
}
