@value colors: "../../../../../components/library/style/colors.css";
@value color-purple-bg, color-purple-dark, color-purple, color-error-light, color-error-dark, color-success-light, color-success-dark, color-text-subtle from colors;

.relative {
    position: relative;
}

.dropdown {
    position: absolute;
    z-index: 10;

    width: 100%;
    border-radius: 5px;

    background-color: white;
    box-shadow: 0 4px 15px rgba(59, 77, 181, 0.16);
}

.label {
    padding: 0 8px;
    margin: 4px 0 0;

    font-size: 14px;
    color: color-text-subtle;
}

.buttonGroup {
    padding: 8px;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    row-gap: 4px;
}

.button {
    width: max-content;
    height: 32px;
    border-radius: 5px;
}

.error, .empty {
    color: color-error-dark;
    background-color: color-error-light;
}

.success, .run {
    color: color-success-dark;
    background-color: color-success-light;
}

.waiting, .undefined {
    color: color-purple-dark;
    background-color: color-purple-bg;
}

.default {
    display: flex;
    align-items: center;
    padding: 0 8px;
    height: 42px;
    transition: .3s;
}

.default:hover {
    background-color: #F5F5FA;
}

.default .icon {
    color: color-purple;
    margin-right: 8px;
}

.blue {
    background-color: color-purple-bg;
    color: color-purple-dark;
}

.red {
    background-color: color-error-light;
    color: color-error-dark;
}

.green {
    background-color: color-success-light;
    color: color-success-dark;
}