.container {
    width: 100%;
    height: 100%;
    min-height: 195px;

    padding: 0 16px;
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.title {
    font-size: 24px;
    font-weight: 500;
}
