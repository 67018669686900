.background {
    background-color: #F5F5FA;
}

.content {
    padding: 8px;
}

.header {
    display: flex;
    justify-content: space-between;
}

.actions {
    display: flex;
    column-gap: 8px;
}

.header h3 {
    margin: 8px 0 0;
    font-size: 18px;
}

.issuer {
    margin-top: 0;
    margin-bottom: 4px;
}

.list {
    padding-left: 0;

    display: flex;
    flex-wrap: wrap;
    row-gap: 16px;
    column-gap: 16px;
}

.item {
    list-style: none;
    min-width: 200px;

    display: flex;
    flex-direction: column;

    background-color: white;
    border-radius: 5px;
    padding: 8px;
}

.bold {
    font-weight: 500;
}

.password {
    display: flex;
    align-items: center;
}

.button {
    padding: 4px 8px;
    margin-left: 4px;
    min-height: unset;
    height: unset;
    min-width: unset;
    font-size: 12px;
}
