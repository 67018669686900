.search {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    padding: 16px;
}

.searchbar {
    width: 45%;
    height: 48px;
}

.searchbar > div {
    height: 48px;
}

.auth {
    width: 15%;
    height: 48px;
    margin-left: 8px;
}

.auth > div {
    height: 48px;
}

.submit {
    margin-left: 16px;
    width: 100%;
    max-width: 250px;
}
