@value colors: "../../style/colors.css";
@value purple-lighten-5, base-dark-grey, base-purple from colors;

.icon {
  margin-right: 8px;
  transition: color .3s;
}

.iconAnimate {
    transform: rotateZ(0deg);
    animation: rotate 0.3s linear ;
    color: base-purple;
}

.label {
  margin-right: 16px;
}

.label,  .optionSelected {
  margin-bottom: 0;
}

.optionSelected {
  color: black;
}

.selected {
  background-color: base-purple !important;
  color: white !important;
  border: 1px solid base-purple !important;
}

.selected .optionSelected {
  color: white;
}

.selected .iconAnimate {
  color: white;
}

.text {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: 16px;
}

.text p {
  margin: 0;
}

.text p + p {
  margin-left: 8px;
}

.optionContainer {
  min-width: 180px;
  padding: 8px;
  margin-top: 8px;

  box-shadow: -1px 7px 9px 0px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  background-color: white;

  position: absolute;
  z-index: 100;
}

.optionContainer option {
  padding: 8px;
  border-radius: 5px;
}

.optionContainer option:hover {
  background-color: purple-lighten-5;
  cursor: pointer;
}


@-webkit-keyframes rotate {
  from {transform: rotateZ(180deg);}
  to {transform: rotateZ(0deg);}
}
@-moz-keyframes rotate {
  from {transform: rotateZ(180deg);}
  to {transform: rotateZ(0deg);}
}
@keyframes rotate {
  from {transform: rotateZ(180deg);}
  to {transform: rotateZ(0deg);}
}

@media screen and (max-width: 768px) {
  .label, .text, .text p {
    font-size: 14px;
  }

  .label {
    margin-right: 8px;
  }

  .text p + p {
    margin: 0;
  }
}
