@value colors: "../../../components/library/style/colors.css";
@value color-purple-gray, color-text-subtle from colors;

.main {
    padding: 24px;
}

.button {
    height: 48px;
    font-weight: 400;
    border-bottom: 3px solid color-purple-gray;
    color: color-text-subtle;
    transition: .3s;
}

.selected, .button:hover {
    color: black;
    border-bottom: 3px solid black;
}

.button + .button {
    margin-left: 16px;
}

