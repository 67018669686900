.input {
  height: 42px;
  width: 100%;
  border: 1px solid #C4C4C4;
  box-sizing: border-box;
  border-radius: 5px;
  color: #AEADBE;
  padding-left: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  font-family: 'Roboto', sans-serif;
}

.textarea {
  width: 100%;
  border: 1px solid #C4C4C4;
  box-sizing: border-box;
  border-radius: 5px;
  color: #AEADBE;
  padding-left: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: white;
}

.input .ant-tooltip {
  display: none;
}

.input input {
  border: none;
  height: 40px;
  width: 98%;
  box-sizing: border-box;
  outline: none;
  background: none;
  color: black;
  font-family: 'Roboto', sans-serif;
}

.textarea textarea {
  border: none;
  height: 250px;
  width: 98%;
  margin: auto;
  box-sizing: border-box;
  outline: none;
  background: none;
  color: black;
  resize: none;
}

.input:focus-within, .inputActive, .input:hover {
  border-color: #526AF2;
  outline: none;
  transition: .3s;
  cursor: pointer;
}

.input:focus-within {
  box-shadow: 0px 0px 6px #b1bdff;
}

.label {
  margin-bottom: 8px;
  text-align: left;
}

.hint {
  font-size: 12px;
  margin-top: 4px;
}

@media screen and (max-width: 450px) {
  .input {
    height: unset;
    min-height: 42px;
  }
}
