.history {
  padding: 24px;
}

.grid {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  border-bottom: 1px solid white;
  grid-gap: 8px;
}

.list {
  margin: 0;
  padding: 0;
  height: 75vh;
  overflow-y: scroll;
  scrollbar-color: rgba(0, 0, 0, 0.1) transparent; /* thumb and track color */
  scrollbar-width: thin;
}

.list::-webkit-scrollbar {
  width: 5px;  /* for vertical scrollbars */
}

.list::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.row {
  padding: 0 16px;
}
