@value colors: '../../../../components/library/style/colors.css';
@value color-purple, color-gray-light, color-text-subtle from colors;

.reader {
  width: 66%;
}
.error {
  width: 100%;
  height: 60vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.errorPopup {
  min-height: 700px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.errorPopup p {
  font-size: 24px;
  text-align: center;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 16px;
}

.errorPopup button {
  color: color-purple;
}

.errorPopup .hint {
  font-size: 18px;
  font-weight: 400;
  margin-top: 8px;
  padding: 8px;
  background-color: color-gray-light;
  border-radius: 5px;
}

.date {
  color: color-text-subtle;
  text-transform: capitalize;
  margin-bottom: 0;
}

.title {
  font-weight: 500;
  font-size: 24px;
  margin-top: 0;
  margin-bottom: 16px;
}

.contact p {
  margin: 0;
}

.text {
  width: 100%;
  max-width: 40vw;
  border-radius: 5px;
  color: color-text-subtle;
  background-color: color-gray-light;
  padding: 8px;
  min-height: 250px;
  box-sizing: border-box;
}

.text.empty {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
