@value colors: "../../../../components/library/style/colors.css";
@value color-purple, color-purple-dark, color-purple-gray, color-gray, color-error from colors;

.toolbar {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.ghost {
  display: flex;
  flex-direction: row;
  align-items: center;

  transition: .3s;
}

.ghost .icon {
  color: color-purple;
  margin-right: 8px;
}

.ghost:hover {
  color: color-purple;
}

.buttonGroup {
  display: flex;
  flex-direction: row;
}

.buttonGroup .tool + .tool {
  margin-left: 16px;
}

.button {
  background-color: color-purple;
  border-radius: 5px;
  color: white;

  display: flex;
  flex-direction: row;
  align-items: center;

  height: 48px;
  padding: 0 16px;
  transition: .3s;
}

.button:hover {
  background-color: color-purple-dark;
}

.import {
  position: relative;
}

.importChoice {
  position: absolute;
  right: 0;
  width: 450px;

  list-style: none;
  padding:  16px 0;

  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.importChoice button {
  font-weight: 400;
  height: 42px;
  font-size: 16px;
  width: 100%;
  text-align: left;
}

.importChoice button:hover, .importChoice button:focus {
  background-color: color-purple-gray;
}

.disabled {
  background-color: color-gray;
}

.disabled:hover {
  background-color: color-gray;
}

.more {
  width: 48px;
  height: 48px;
  border-radius: 48px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.more .icon {
  margin-right: 0;
}

.more:hover {
  background-color: color-purple-gray;
}

.import {
  position: relative;
  right: 0;
}

.importPopUp {
  position: absolute;
  right: 0;
  width: 250px;

  list-style: none;
  padding:  16px 0;

  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  display: flex;
  flex-direction: column;
}

.importButton {
  height: 42px;
  font-weight: 400;

  display: flex;
  flex-direction: row;
  align-items: center;

  color: color-error;
}

.importButton:hover, .optionButton:hover {
  background-color: color-purple-gray;
}

.optionButton {
  height: 42px;
  font-weight: 400;

  display: flex;
  flex-direction: row;
  align-items: center;
}

.optionButton .icon {
  color: color-purple;
}

.icon {
  margin-right: 16px;
}

.blacklist {
  margin: 0 16px;;
}

@media screen and (max-width: 1024px) {
  .buttonGroup {
    display: none;
  }
}
