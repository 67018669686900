@value colors: "../../components/library/style/colors.css";
@value color-purple, color-purple-gray, color-gray-light, color-text-subtle from colors;

.contacts {
  margin-left: 320px;
  width: 100%;
}

.contacts header {
  position: relative;
  min-height: 110px;
}

.contacts h1 {
  margin-bottom: 16px;
}

.searchbar {
  width: 95%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  box-sizing: border-box;
  background-color: white;
  padding: 8px;
  border-radius: 5px;

  position: absolute;

  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
}

.searchbar input {
  width: 90%;
  box-sizing: border-box;
  padding: 8px 8px 8px 0;
  border: none;
  outline: none;
}

.main {
  margin-top: 50px;
  padding: 24px;
}

.projectList {
  display: flex;
  flex-direction: row;

  margin-top: 0;
  list-style: none;
  padding-left: 0;
}

.projectList li {
  padding-left: 0;
  list-style: none;
}

.button {
  color: color-purple;
  border: 1px solid color-purple;
  height: 32px;
  min-width: 32px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: .3s;
}

.selected, .button:hover {
  color: white;
  background-color: color-purple;
  height: 32px;
  min-width: 32px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.subtitle {
  margin-top: 0;
  margin-bottom: 8px;

  color: color-text-subtle;
  text-transform: uppercase;
  font-weight: 500;
}

.hint {
  color: color-text-subtle;
}
