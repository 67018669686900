@value colors: "../library/style/colors.css";
@value color-purple, color-purple-gray, color-gray-light, color-text-subtle from colors;

.details {
  background-color: white;
  width: 100%;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
}

.header {
  padding: 16px 24px;
}

.header h2 {
  margin: 0;
}

.row {
  display: flex;
  flex-direction: row;
}

.row p {
  color: color-text-subtle;
}

.row p + p {
  margin-left: 24px;
}

.main {
  padding: 16px;
  background-color: #f9f9f9;
  color: color-text-subtle;
}

.grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-row-gap: 16px;
}

.segmentation p {
  margin: 0;
  font-size: 14px;
  text-transform: capitalize;
}

.subtitle {
  margin-top: 16px;;
  margin-bottom: 24px;
  font-size: 18px;
}

.separator {
  border: none;
  border-bottom:  1px solid color-gray-light;
  margin: 24px 0;
}


.highlight {
  font-size: 14px;
  color: color-text-subtle;
  margin-bottom: 0;
}
