@value colors: "../../components/library/style/colors.css";
@value color-purple-gray, color-text-subtle from colors;

.project {
  margin-left: 320px;
  width: 100%;
}

.header {
  width: 100%;
  padding: 32px 24px 0 24px;;
  box-sizing: border-box;
  background-color: color-purple-gray;
}

.subtitle {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  text-transform: uppercase;
  color: #4F67F3;

  margin: 0;
}

.title {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  margin-top: 4px;
  margin-bottom: 32px;
}

.button {
  height: 48px;
  font-weight: 400;
  border-bottom: 3px solid color-purple-gray;
  color: color-text-subtle;
  transition: .3s;
}

.selected, .button:hover {
  color: black;
  border-bottom: 3px solid black;
}

.button + .button {
  margin-left: 16px;
}

.error {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.error .title {
  margin-bottom: 0;
}

@media screen and (max-width: 1024px) {
  .project {
    margin-left: 0;
  }
}
