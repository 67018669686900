@value colors: "../../components/library/style/colors.css";
@value color-purple, color-purple-bg, color-purple-dark, color-purple-gray, color-gray-light, color-text-subtle, color-error-light, color-error-dark from colors;

.main {
  padding: 24px;
}

.markdown pre {
  padding: 16px;
  max-width: calc(100vw - 450px);
  background-color: color-gray-light;
  border-radius: 5px;
  overflow-y: scroll;
}


.markdown td, .markdown th {
  border: none;
  padding: 8px;
}

.markdown tr:nth-child(even){background-color: color-gray-light;}

.markdown tr:hover {background-color: #ddd;}

.markdown th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: color-purple;
  color: white;
}
