@value colors: "../../../components/library/style/colors.css";
@value color-purple, color-error, color-error-light from colors;

.contacts {
  padding: 24px;
}

.contactsNumber span {
  margin-left: 4px;
  font-weight: 500;
  color: color-purple;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
 .row p + p {
   margin-left: 16px;
 }

 .icon {
     margin-right: 8px;
     color: color-error;
 }

 .error {
     background-color: color-error-light;
     padding: 8px;
     border-radius: 5px;
     margin-top: 16px;

     display: flex;
     flex-direction: row;
 }

 .error p:first-child {
     margin-top: 0;
 }

 .error p:last-child {
     margin-bottom: 0;
 }

 .title {
     font-size: 14px;
     margin-bottom: 0;
     color: color-error;
 }

 .information {
     margin-top: 4px;
     font-weight: 500;
 }
