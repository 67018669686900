@value colors: "../../../components/library/style/colors.css";
@value color-purple, color-text-subtle, color-error-dark, color-error-light, color-success-dark, color-success-light, color-purple-dark, color-purple-bg from colors;

.grid {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr 1fr 0.25fr;
}

.header {
    background-color: #F5F5FA;
    border-radius: 5px 5px 0 0;
    padding: 0 13px 0 8px;
}

.header button {
    font-weight: 500;
    text-align: left;
    height: 42px;
}

.row {
    padding: 0 8px;
    border-bottom: 1px solid #F5F5FA;
}

.body {
    max-height: 70vh;
    overflow-y: scroll;
    scrollbar-color: rgba(0, 0, 0, 0.1) transparent; /* thumb and track color */
    scrollbar-width: thin;
}

.body::-webkit-scrollbar {
    width: 5px;  /* for vertical scrollbars */
}

.body::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.1);
    border-radius: 5px;
}

.actions {
    color: color-text-subtle;
}

.status {
    font-weight: 500;
    padding: 4px 8px;
    border-radius: 5px;
    text-align: center;
    width: max-content;
}

.error, .empty {
    color: color-error-dark;
    background-color: color-error-light;
}

.success, .run {
    color: color-success-dark;
    background-color: color-success-light;
}

.waiting, .undefined {
    color: color-purple-dark;
    background-color: color-purple-bg;
}

.default {
    display: flex;
    align-items: center;
    padding: 0 8px;
    height: 42px;
    transition: .3s;
}

.default:hover {
    background-color: #F5F5FA;
}

.default .icon {
    color: color-purple;
    margin-right: 8px;
}

.blue {
    background-color: color-purple-bg;
    color: color-purple-dark;
}

.red {
    background-color: color-error-light;
    color: color-error-dark;
}

.green {
    background-color: color-success-light;
    color: color-success-dark;
}
