@value colors: "../../../../../components/library/style/colors.css";
@value color-purple, color-purple-dark, color-purple-gray from colors;

.filters {
  margin-top: 8px;
  padding: 8px;

  background-color: color-purple-gray;
}

.clear {
  color: color-purple;
  display: flex;
  align-items: center;
  transition: .3s;
  margin-bottom: 8px;
}

.clear:hover {
  color: color-purple-dark;
}

.icon {
  margin-left: 8px;
}

.row {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 8px;
}

@media screen and (max-width: 600px) {
  .row {
    grid-template-columns: repeat(2, 1fr);
  }
}
