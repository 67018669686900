@value colors: "../../../../components/library/style/colors.css";
@value color-purple, color-purple-dark, color-purple-gray, color-error, color-success from colors;

.container {
  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;

  background: rgba(245, 245, 250, 0.69);
}

.upload {
  background-color: white;
  border-radius: 5px;

  width: 45%;
  padding: 18px;

  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.15);
}

.upload h1 {
  margin: 0;

  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
}

.dropzone {
  background: #F5F5FA;
  border-radius: 5px;

  width: 100%;
  height: 175px;
  margin-top: 16px;
  margin-bottom: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  transition: .3s;
}

.label {
  font-family: Roboto;
  color: #838383;
  margin-top: 4px;
}

.button {
  width: 100%;
  height: 48px;

  color: white;
  background-color: color-purple;
  border-radius: 5px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.button:hover {
    background-color: color-purple-dark;
}

.iconContainer {
  width: 48px;
  height: 48px;

  display: flex;
  justify-content: center;
  align-items: center;

  border: 1px solid #CFD6FF;
  box-sizing: border-box;
  border-radius: 5px;
}

.icon {
  color: color-purple;
}

.dropzoneText {
  display: flex;
  flex-direction: row;
}

.dropzoneButton {
  color: color-purple;
}

 .dropzoneButton:hover {
   color: color-purple-dark;
 }

.select {
  border: 1px solid #DADCDE;
  box-sizing: border-box;
  border-radius: 5px;

  width: 100%;
  height: 48px;
  padding: 0 8px;
  box-sizing: border-box;

  margin-bottom: 24px;
  margin-top: 0;
}

.selectLabel {
  margin-bottom: 0;
}

.fileUpload {
  position: fixed;
  bottom: 48px;
  right: 48px;

  background-color: white;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.15);

  width: 350px;
  min-height: 65px;
  padding: 16px;

  display: flex;
  flex-direction: row;
  align-items: center;
}

.fileUpload .icon {
  font-size:  48px;
  margin-right: 16px;
  width: 10%;
}

.row {
  width: 100%;
  display: flex;
  flex: row;
  justify-content: space-between;
  align-items: center;
}

.row p {
  margin: 0;
  font-size: 16px;
}

.progress {
  width: 90%;
}

.barBackground {
  width: 100%;
  height: 8px;
  background-color: color-purple-gray;
  margin-top: 8px;
  border-radius: 5px;
}

.bar {
  height: 100%;
  background-color: color-purple;
  border-radius: 5px;
  transition: 1s;
}

.cancel {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #838383;
}

.bold {
  font-weight: 500;
}

.error {
  color: color-error;
  margin-top: 4px;
  font-size: 14px;
}

.file {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  padding: 8px;
  background-color: color-purple-gray;
  border-radius: 5px;
  margin-top: 16px;
}

.rowFile {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.fileIcon {
  margin-right: 16px;
  font-size: 36px;
  color: color-purple;
}

.name {
  margin-right: 16px;
}

.clear {
  color: #838383;
  transition: .3s;
}

.clear:hover {
  color: color-purple;
}

.hovered {
  background-color: #dce1ff;
}

.success {
  margin: 0;
  font-weight: 500;
}

.successIcon {
  color: color-success;
  font-size: 48px;
  margin-right: 8px;
}

.success {
  background: color-success;
}

.fadeOut{
  opacity:0;
  transition: width 0.5s 0.5s, height 0.5s 0.5s, opacity 0.5s;
}
